<template>
  <div class="animated fadeIn">
    <div id="snackbar"></div>
    <b-row>
      <b-col sm="12">
        <b-card>
          <b-row>
            <b-col sm="12" id="preview" class="mb-3"></b-col>
          </b-row>
          <div slot="header">
            <strong>{{ staticNames.name }}&nbsp;Event Manager</strong>
            <small>Form</small>
          </div>
              <b-row>
              <b-col sm="4">
                <b-form-group>
                  <label for="Title">Event Manager Name</label>
                  <b-form-input
                    type="text"
                    required
                    v-model="staticBanner.em_name"
                    id="Name"
                    placeholder="Name"
                  ></b-form-input>
                </b-form-group>
              </b-col>    
              <b-col sm="4" v-if="staticNames.name == 'Add'">
                <b-form-group>
                  <label for="Title">Email</label>
                  <b-form-input
                    type="text"
                    required
                    v-model="staticBanner.em_email"
                    id="Email"
                    placeholder="Email"
                  ></b-form-input>
                </b-form-group>
              </b-col>    
              <b-col sm="4">
                <b-form-group>
                  <label for="Title">Password</label>
                  <b-form-input
                    type="text"
                    required
                    v-model="staticBanner.em_password"
                    id="Password"
                    placeholder="Password"
                  ></b-form-input>
                </b-form-group>
              </b-col>             
            </b-row>

             <b-row>
              <b-col sm="4" v-if="staticNames.name == 'Add'">
                <b-form-group>
                  <label for="Title">Phone</label>
                  <b-form-input
                    type="text"
                    required
                    v-model="staticBanner.em_phone"
                    id="Phone"
                    placeholder="Phone"
                  ></b-form-input>
                </b-form-group>
              </b-col>    
              <b-col sm="4">
                <b-form-group>
                  <label for="Title">Address</label>
                  <b-form-input
                    type="text"
                    required
                    v-model="staticBanner.em_address"
                    id="Address"
                    placeholder="Address"
                  ></b-form-input>
                </b-form-group>
              </b-col>          
            </b-row>

            <b-row>
                <b-col sm="4">
                <b-form-checkbox v-model="staticBanner.em_can_public" name="check-button" switch>
                    Public 
                </b-form-checkbox>
              </b-col>  
              <b-col sm="4">
                <b-form-checkbox v-model="staticBanner.em_can_private" name="check-button" switch>
                    Private 
                </b-form-checkbox>
              </b-col>  
            </b-row>
 
            <b-row class="mt-3">
              <b-col sm="3">
                <b-button @click="SaveEvent()" size="sm" variant="primary">
                  <i class="fa fa-dot-circle-o"></i>
                  {{ staticNames.name }}
                </b-button>
              </b-col>
              <b-col sm="3">
                <b-button v-if="staticNames.name == 'Add'" @click="resetMovieBanner" size="sm" variant="success">
                  <i class="fa fa-dot-circle-o"></i> Reset
                </b-button>
              </b-col>
            </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import MasterService from "@/services/MasterService";
import { Switch as cSwitch } from "@coreui/vue";

export default {
  data() {
    return {
        staticBanner:{
            em_name:'',
            em_email: '',
            em_phone: '',
            em_address: '',
            em_can_public: 2,
            em_can_private: 2,
            em_password:'',
        },
      staticNames: {
        name: "Add",
      },
    };
  },
  components: {
    cSwitch,
  },
  computed: {},
  watch: {},
  beforeMount() {
    const { event_manager_id } = this.$route.query;
    if (event_manager_id) {
      this.staticNames.name = "Update";
      this.getEventManagerDetails(event_manager_id);
    }
  },
  mounted: function () {},
  methods: {
      ReturnTrueFalse(BooleanValue){
            if(BooleanValue == true){
                return 1;
            }else{
                return 2;
            }
      },
      ReturnValue(value){
            if(value == 1){
                return true;
            }else{
                return false;
            }
      },

      SaveEvent(){
          let em_can_public = this.ReturnTrueFalse(this.staticBanner.em_can_public);
          let em_can_private = this.ReturnTrueFalse(this.staticBanner.em_can_private);

          let payload={
              em_name:this.staticBanner.em_name,
              em_email:this.staticBanner.em_email,
              em_phone:this.staticBanner.em_phone,
              em_address:this.staticBanner.em_address,
              em_can_public:em_can_public,
              em_can_private:em_can_private,
              em_password: this.staticBanner.em_password
          }
        
        const { event_manager_id } = this.$route.query;
        if (event_manager_id) {
           let updatePayload={
              em_name:this.staticBanner.em_name,
              em_address:this.staticBanner.em_address,
              em_can_public:em_can_public,
              em_can_private:em_can_private,
              em_password: this.staticBanner.em_password
          }
          MasterService.updateEventManager(updatePayload, event_manager_id)
          .then((response) => {
            const { data } = response;
            this.showMessageSnackbar(
                data.message
                  ? data.message
                  : data.error
                  ? data.error.message
                    ? data.error.message
                    : data.error
                  : data
              );
            if(data.message) {
              setTimeout(()=> {
                this.$router.push("/events/eventmanagerlist")
              },3000)
            }
          })
          .catch((error) => {
            console.log("Catch on Banner-getBannerByID Error: ", error);
            this.showMessageSnackbar(
              "Oops! Something went wrong. Kindly contact your administrator"
            );
          });
        }
        else {
          MasterService.addEventManager(payload)
          .then((response) => {
            const { data } = response;
            console.log("response", response);
             this.showMessageSnackbar(
                data.message
                  ? data.message
                  : data.error
                  ? data.error.message
                    ? data.error.message
                    : data.error
                  : data
              );
            if(data.message) {
              setTimeout(()=> {
                this.$router.push("/events/eventmanagerlist")
              },3000)
            }
          })
          .catch((error) => {
            console.log("Catch on Banner-getBannerByID Error: ", error);
            this.showMessageSnackbar(
              "Oops! Something went wrong. Kindly contact your administrator"
            );
          });
        }

      },
      
    getEventManagerDetails: function (event_manager_id) {
      let payload = {
        event_manager_id
      };

      MasterService.getEventManager(payload)
        .then((response) => {
          const { data } = response;
          console.log("response", response);
            this.staticBanner.em_name = data.data.em_name;
            this.staticBanner.em_email = data.data.em_email;
            this.staticBanner.em_phone = data.data.em_phone;
            this.staticBanner.em_address = data.data.em_address;
            this.staticBanner.em_can_public = this.ReturnValue(data.data.em_can_public);
            this.staticBanner.em_can_private = this.ReturnValue(data.data.em_can_private);
            this.staticBanner.em_password = data.data.em_password;
        })
        .catch((error) => {
          console.log("Catch on Banner-getBannerByID Error: ", error);
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
        
    resetMovieBanner: function () {
      this.staticBanner.em_name = "";
      this.staticBanner.em_email = "";
      this.staticBanner.em_phone = "";
      this.staticBanner.em_address = "";
      this.staticBanner.em_can_public = false;
      this.staticBanner.em_can_private = false;
      this.staticBanner.em_password = "";
    },

    // Show Error Message
    showMessageSnackbar: function (message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function () {
        x.className = x.className.replace("show", "");
      }, 3000);
    },
  },
};
</script>

<style></style>
